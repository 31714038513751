@font-face {
  font-family: Broadway;
  src: url('../../resources/fonts/Broadway/BROADW.TTF');
}

@font-face {
  font-family: Mangueira;
  src: url('../../resources/fonts/Mangueira/Mangueira-Regular.ttf');
}

@font-face {
  font-family: Helvetica;
  src: url('../../resources/fonts/Helvetica/Helvetica-Regular.otf');
}

@font-face {
  font-family: Snap ITC;
  src: url('../../resources/fonts/SnapITC/SNAP.TTF');
}

@font-face {
  font-family: Stencil;
  src: url('../../resources/fonts/Stencil/STENCIL.TTF');
}