.container {
  position: relative;
  width: 100%;
  height: 10rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgb(235, 230, 230);
}

.container:hover .overlay {
  opacity: 0.8;
}