.Toastify__toast-container {
    word-break: break-word;
    width:100%;
    top:0;
    align-items: center;
    display: inline-block;
    padding-left: 0;
    padding-right:0;
    padding-top:0;
}

.Toastify__toast {
    text-align: left;
    padding-left: 4.375rem;
    padding-top:0rem !important;
    padding-bottom:0rem !important;
    border-radius: 0rem;
    color: #000;
}

.Toastify__toast--info {
    background-color: #E5FFE8;
}
.Toastify__toast--error {
    background-color: #FFF1F1;
}
.Toastify__toast--warning {
    background-color: #FFFAE5;
}

.Toastify__toast p{
    display: inline-block;
    padding-left:1.875rem;
    vertical-align:middle;
    align-items: center;
}

.Toastify__toast img{
    display:inline-block;
    vertical-align:middle;
    padding:0rem !important;
    margin:0rem;
}

.Toastify__toast .toastContainer {
    display: inline-flex;
}

@media screen and (max-width:560px) {
    .Toastify__toast {
        padding-left:2rem;
        padding-right: 2rem;
    }
}